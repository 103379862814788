(function() {
    'use strict';

    angular.module('mfgApp')
        .factory("instructionsMFG", InstructionsMFGFactory);

    InstructionsMFGFactory.$inject = ['routesConstant'];

    function InstructionsMFGFactory(routesConstant) {
        var pages = {};

        (function initMFGPages(pages, mfg) {
            pages[mfg.DASHBOARD.stateName] = {
                title: "Operations Dashboard",
                type: "Operations Dashboard",
                instructions: 'Help is being defined.',
                actions: [
                    {icon: "", instructions: ""}
                ]
            };

            pages[mfg.LICENSING.stateName] = {
                title: "Generate Licensekey",
                type: "Generate Licensekey",
                instructions: 'Help is being defined.',
                actions: [
                    {icon: "", instructions: ""}
                ]
            };

            pages[mfg.ROGUE] = {
                title: "Manage Hardware Device",
                type: "Manage Hardware Device",
                instructions: 'Help is being defined.',
                actions: [
                    {icon: "plus", instructions: ""},
                    {icon: "upload", instructions: ""},
                    {icon: "indent", instructions: ""},
                    {icon: "trash", instructions: ""}
                ]
            };

        })(pages, routesConstant.MFG);

        return pages;
    }

})();
